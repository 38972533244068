import React, { useState } from 'react'
import { getAppConfigurationsByApp, getTransactionsByAppId } from '../../services/apiApps'

// style
import { Avatar } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { CardHeader } from '@material-ui/core'
import { List } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemAvatar } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TouchApp } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import CardBody from 'components/Card/CardBody'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'

const useStyles = makeStyles(styles)

export default function ApplicationListApp({ callbackAppChanged }) {
  const classes = useStyles()

  const [appList, setAppList] = useState([])
  const [selectedAppId, setSelectedAppId] = useState(null)

  const history = useHistory()

  const { isFetching: isFetchingAppList } = useQuery(
    'AppList',
    () => getAppConfigurationsByApp('slp'),
    {
      onSuccess: async response => {
        if (response?.success) {
          const data = response.data
          if (data?.length > 0) {
            setAppList(data)
            if (data.length !== 0) {
              if (!selectedAppId) {
                handleSelectApp(data[0].env)
              }
            }
          }
        } else {
          if (response) {
            let message = response.message
            if (!message) {
              message = 'Something went wrong, please try again later.'
            }
          }
        }
      },
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false
    }
  )

  const { isFetching: isFetchingAppErrors } = useQuery(
    'ErrorCount',
    () =>
      Promise.all(
        appList.map(app => getAppListErrorCount(app).then(response => (app.errors = response)))
      ),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!appList.length
    }
  )

  const getAppListErrorCount = async (app, status = 'failed') => {
    const response = await getTransactionsByAppId('slp', app.env, status)
    if (response && response.success) {
      return response.data.transactions.length
    }
  }

  const onErrors = appId => {
    history.push(`/admin/dataAssure/${appId}/transactions`)
  }

  const handleSelectApp = appId => {
    if (appId !== selectedAppId) {
      setSelectedAppId(appId)
      callbackAppChanged(appId)
    }
  }

  return (
    <>
      <Card className={classes.appBar}>
        <CardHeader subheader="Application List" />
        <CardBody style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          {(isFetchingAppList && <SyncLoader showLoader={true} textLoader="Loading apps..." />) ||
            (appList && (
              <List>
                {appList.map((app, key) => {
                  return (
                    <ListItem
                      style={{ paddingLeft: '5px', paddingRight: '5px' }}
                      className={classes.listItem}
                      button
                      selected={selectedAppId === app.env}
                      key={key}
                      onClick={() => handleSelectApp(app.env)}
                    >
                      <ListItemAvatar>
                        {selectedAppId === app.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={app.env} secondary={`Type: ${app.type}`} />
                      {(isFetchingAppErrors && <SyncLoader showLoader={true} textLoader="" />) ||
                        (!!app.errors && (
                          <ListItemText style={{ marginLeft: '4px' }}>
                            <Button
                              className={classes.textErrors}
                              onClick={() => onErrors(app.env)}
                            >
                              <ReportProblemIcon fontSize="small" />
                              {app.errors} {app.errors === 1 ? 'Error' : 'Errors'}
                            </Button>
                          </ListItemText>
                        ))}
                    </ListItem>
                  )
                })}
              </List>
            ))}
        </CardBody>
      </Card>
    </>
  )
}
