import { useEffect, useState } from 'react'
// const url = 'https://apis.google.com/js/api.js';

const injectorState = new Map()

const loadedState = { loaded: true, error: false }
const errorState = { loaded: true, error: true }

export default function useScript(url) {
  const [state, setState] = useState({ loaded: false, error: false })

  useEffect(() => {
    let script = injectorState.get(url)
    if (!script) {
      script = {
        status: 'init',
        domElement: null
      }
      injectorState.set(url, script)
    }

    if (script.status === 'loaded') {
      setState({ ...loadedState })
      return
    }

    if (script.status === 'error') {
      setState({ ...errorState })
      return
    }

    const load = () => {
      setState({ ...loadedState })
      script.status = 'loaded'
    }

    const error = () => {
      console.log('error loading the script')
      setState({ ...errorState })
      script.domElement.remove()
      script.status = 'error'
    }

    if (!script.domElement) {
      script.domElement = document.createElement('script')
      script.domElement.src = url
      script.domElement.async = true
      document.body.append(script.domElement)
      script.domElement.addEventListener('load', load)
      script.domElement.addEventListener('error', error)
      script.status = 'loading'
    }

    // remove the event listeners
    return () => {
      //checks the main injector instance
      //prevents Cannot read property 'removeEventListener' of null in hot reload
      if (!script.domElement) {
        return
      }

      script.domElement.removeEventListener('load', load)
      script.domElement.removeEventListener('error', error)
    }
  }, [url])

  return [state.loaded, state.error]
}
