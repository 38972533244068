import { ApiPii } from 'utils/Constants'
import axios from 'axios'
import { createHeaders } from 'utils/functions'

async function getSuppliersData(tenantId, status) {
  const url = `${ApiPii.EndPoint}/tenant/${tenantId}/suppliersData`
  const headers = createHeaders()
  return axios({
    method: 'get',
    url,
    headers,
    params: { status: `${status}` }
  })
}

async function updateSupplierEmail(haskKey, validationId, email) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/supplierData`
  const headers = createHeaders()
  return axios({
    method: 'put',
    url,
    headers,
    data: { email }
  })
}

async function rejectPiiValidationManually(haskKey, validationId, message) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/manual-rejection`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { message }
  })
}

async function resendSupplierInvitation(haskKey, validationId) {
  const url = `${ApiPii.EndPoint}/sendPiiFormInvitation`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { tenantId: haskKey, taskId: validationId }
  })
}

export { getSuppliersData, rejectPiiValidationManually, resendSupplierInvitation, updateSupplierEmail }
