import React from 'react'
import PropTypes from 'prop-types'

// Core
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'

// Views
import ConnectivityView from './ConnectivityView'

// Icons
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Info from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Style
import style from '../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle'
import withStyles from '@material-ui/core/styles/withStyles'

class ParametersAccordion extends React.Component {
  constructor (props) {
    super(props)
    this.countKey = 0
    let state = {}
    state.parameters = []
    state.hasErrors = []
    if (props.initialState && props.initialState.parameters) {
      props.initialState.parameters.forEach(parameter => {
        state.hasErrors.push(false)
        state.parameters.push({
          expanded:
            parameter.expandedAccordion !== undefined
              ? parameter.expandedAccordion
              : false,
          version: this.getParameterVersion(parameter.version)
        })
      })
    }

    this.state = state
  }

  sendState () {
    return this.state
  }
  getParameterVersion (value) {
    if (value === undefined) return 1
    else return value
  }
  componentWillUnmount () {
    this._Mounted = false
  }
  componentDidMount () {
    this._Mounted = true
  }
  componentDidUpdate () {
    if (this._Mounted) {
      if (this.props.initialState.parameters) {
        for (
          let index = 0;
          index < this.props.initialState.parameters.length;
          index++
        ) {
          if (
            this.getParameterVersion(
              this.props.initialState.parameters[index].version
            ) !== this.state.parameters[index].version
          ) {
            this.expandAccordion(
              index,
              this.props.initialState.parameters[index].expandedAccordion,
              this.getParameterVersion(
                this.props.initialState.parameters[index].version
              )
            )
          }
        }
      }
    }
  }

  expandAccordion (id, value, version) {
    if (this._Mounted) {
      let newState = { ...this.state }
      if (this.state && this.state.parameters) {
        for (let index = 0; index < newState.parameters.length; index++) {
          if (id === undefined) newState.parameters[index].expanded = true
          if (id === index) {
            if (version !== undefined)
              newState.parameters[index].version = version
            if (value === undefined)
              newState.parameters[index].expanded = !newState.parameters[index]
                .expanded
            else newState.parameters[index].expanded = value
          }
        }
      }
      if (this._Mounted) this.setState(newState)
    }
  }
  isValidated () {
    let validated = true
    let newState = this.state
    for (let index = 0; index < this.state.parameters.length; index++) {
      if (this[index] && this[index].isValidated) {
        let indexIsValidated = this[index].isValidated()
        if (!indexIsValidated) {
          if (this._Mounted) {
            newState.hasErrors[index] = true
            this.expandAccordion(index, true)
          }
        }
        if (validated && !indexIsValidated) validated = false
      }
    }

    this.setState(newState)
    return validated
  }

  getBackButton () {
    if (this.props.initialState.backButtonOnClick)
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <IconButton onClick={this.props.initialState.backButtonOnClick}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
      )
    return ''
  }
  getParentObjectValue () {
    let value = undefined
    if (this.props.initialState.parentObject) {
      let keys = Object.keys(this.props.initialState.parentObject)
      keys.forEach(element => {
        let childElement = this.props.initialState.parentObject[element]
        if (childElement.isParentObjectValue) {
          value = childElement.value
          return
        } else if (childElement.isNegativeParentObjectValue) {
          value = !childElement.value
          return
        }
      })
    }
    return value
  }
  getAccordion (index, lock, parameter) {
    if (this.state.hasErrors[index])
      return (
        <Accordion
          ref={this[index + 'ref']}
          disabled={lock}
          style={
            !lock
              ? { width: '100%' }
              : {
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  width: '100%'
                }
          }
          expanded={
            lock
              ? false
              : this.state
              ? this.state.parameters[index].expanded
              : false
          }
          onChange={() => {
            this.expandAccordion(index)
            let newState = this.state
            newState.hasErrors[index] = false
            this.setState(newState)
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{parameter.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ConnectivityView
                innerRef={node => {
                  this[index] = node
                }}
                initialState={parameter}
              ></ConnectivityView>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )
    else
      return (
        <Accordion
          ref={this[index + 'ref']}
          disabled={lock}
          style={
            !lock
              ? { width: '100%' }
              : {
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  width: '100%'
                }
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{parameter.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ConnectivityView
                innerRef={node => {
                  this[index] = node
                }}
                initialState={parameter}
              ></ConnectivityView>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )
  }
  render () {
    const { classes } = this.props
    let parentObjectValue = this.getParentObjectValue()
    let lock = !(
      (parentObjectValue && parentObjectValue === true) ||
      parentObjectValue !== false
    )

    return (
      <div>
        {this.getBackButton()}
        {this.props.initialState.documentationPath ? (
          <Grid item xs={12} sm={12} md={12}>
            <Button
              className={classes.documentation}
              href={this.props.initialState.documentationPath}
              target="_blank"
            >
              <Info className={classes.icons} />
              Download Documentation
            </Button>
          </Grid>
        ) : (
          ''
        )}
        {this.props.initialState.parameters ? (
          this.props.initialState.parameters &&
          (this.props.initialState.parameters.length > 1 ||
            (this.props.initialState.forceAccordion &&
              this.props.initialState.parameters.length === 1)) ? (
            this.props.initialState.parameters.map((parameter, index) => {
              return (
                <Grid
                  container
                  style={
                    this.props.initialState.parentObject
                      ? { paddingTop: '10px' }
                      : { padding: '10px' }
                  }
                  key={this.countKey++}
                >
                  {this.getAccordion(index, lock, parameter)}
                </Grid>
              )
            })
          ) : this.props.initialState.parameters &&
            this.props.initialState.parameters.length === 1 ? (
            <ConnectivityView
              innerRef={node => {
                this[0] = node
              }}
              initialState={this.props.initialState.parameters[0]}
            ></ConnectivityView>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    )
  }
}

ParametersAccordion.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(ParametersAccordion)
