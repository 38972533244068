import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/material-dashboard-pro-react/components/piiRejectModalStyle'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const useStyles = makeStyles(styles)

export function PIIRejectDialog (props) {
  const classes = useStyles()
  const [comment, setComment] = useState('')
  const { onClose, open, rejectPII, title = 'Reject Supplier' } = props
  const handleClose = () => {
    setComment('')
    onClose()
  }

  const submitReject = () => {
    rejectPII(comment)
    setComment('')
    handleClose()
  }

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>{`${title}`}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextareaAutosize
          inputMode={'text'}
          style={{ resize: 'none' }}
          rowsMin={3}
          value={comment}
          placeholder={'Please type a comment for the rejection'}
          onChange={e => {
            setComment(e.target.value)
          }}
        />
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          className={classes.colorPrimary}
          color="primary"
          variant="outlined"
          onClick={() => submitReject()}
        >
          Reject
        </Button>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PIIRejectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
