import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, Hidden } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import FormLabel from '@material-ui/core/FormLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Alert from '@material-ui/lab/Alert'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import DragAndDrop from 'components/DragDropFile/DragDropFile.js'
import Tooltip from '@material-ui/core/Tooltip'

// @material-ui/icons
import Info from '@material-ui/icons/Info'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

// style
import style from '../../assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'
//import Select from '@material-ui/core/Select'
import Select from 'react-select'
import InputLabel from '@material-ui/core/InputLabel'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const fileReader = new FileReader()

class piiEmails extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.initialState
    this.handleFile = this.handleFile.bind(this)
    this.fileReaderOnLoad = this.fileReaderOnLoad.bind(this)
    this.isValidated = this.isValidated.bind(this)
    //this.showSupplierStatus = this.showSupplierStatus.bind(this)
  }

  getParentObjectValue() {
    let value = undefined
    if (this.state.supplierMapping.fields) {
      let keys = Object.keys(this.state.supplierMapping.fields)
      keys.forEach(element => {
        let childElement = this.state.supplierMapping.fields[element]
        if (childElement.isParentObjectValue) {
          value = childElement.value
          return
        } else if (childElement.isNegativeParentObjectValue) {
          value = !childElement.value
          return
        }
      })
    }
    return value
  }

  handleFile(file) {
    fileReader.readAsText(file, 'UTF-8')
    fileReader.onload = this.fileReaderOnLoad
    let newState = this.state
    newState.supplierMapping.fields.piiEncryptionFileName = file.name
    //did upload
    this.setState({ ...newState })
  }
  fileReaderOnLoad(event) {
    console.log('on load file')
    let newState = this.state
    newState.supplierMapping.fields.piiEncryptionKey = fileReader.result
    newState.supplierMapping.fields.piiEncryptionKey = newState.supplierMapping.fields.piiEncryptionKey.replace(
      /\r/g,
      ''
    )
    newState.errorMessage = ''
    newState.error = false
    let uniqueIdCertificate = Date.now() + Math.floor(Math.random() * 100)
    const objCertificateHistory = {
      piiEncriptionUniqueId: uniqueIdCertificate,
      piiEncriptionKeyType: newState.supplierMapping.fields.piiEncriptionKeyType,
      piiEncryptionFileName: newState.supplierMapping.fields.piiEncryptionFileName,
      piiEncryptionId: newState.supplierMapping.fields.piiEncryptionId,
      piiEncryptionKey: newState.supplierMapping.fields.piiEncryptionKey
    }
    newState.supplierMapping.fields.piiEncriptionActualId = uniqueIdCertificate

    if (!Array.isArray(newState.supplierMapping.fields.certificateHistory)) {
      newState.supplierMapping.fields.certificateHistory = []
    }
    newState.supplierMapping.fields.certificateHistory.push(objCertificateHistory)

    this.state.supplierMapping.setSubmitSuccess(true)
    this.state.supplierMapping.setValidationMessage('File loaded succesfully')
    setTimeout(() => {
      this.state.supplierMapping.setSubmitSuccess(false)
    }, 5000)
    this.setState({ ...newState })
  }
  componentDidMount() {
    /*  this.refreshTableWithData = this.refreshTableWithData.bind(this)
    if (this.props.initialState.supplierMapping.refreshTable)
      this.props.initialState.supplierMapping.refreshTable(
        this.refreshTableWithData
      )*/
    console.log('did mount')
    fileReader.onload = this.fileReaderOnLoad
  }
  componentDidUpdate(prevProps, prevState) {}
  sendState() {
    return this.state
  }

  /*showSupplierStatus() {
    const { classes } = this.props
    return (
      <CustomModal
        title={'Suppliers Status'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => {
          let newStatus = this.state
          newStatus.showModal = false
          this.setState({ ...newStatus })
        }}
        onConfirm={() => {
          let newStatus = this.state
          newStatus.showModal = false
          this.setState({ ...newStatus })
        }}
      >
        <PIIEmailsView
          title={'Supplier Information'}
          env={this.state.supplierMapping.env}
          setCreateError={this.state.supplierMapping.setCreateError}
          setValidationMessage={this.state.supplierMapping.setValidationMessage}
          setSubmitSuccess={this.state.supplierMapping.setSubmitSuccess}
        />
      </CustomModal>
    )
  }*/

  isValidated() {
    let validated =
      this.state.supplierMapping.fields.piiEncryptionFileName !== '' &&
      this.state.supplierMapping.fields.piiEncryptionKey !== ''

    if (!validated) {
      let newState = this.state
      newState.errorMessage = 'Please select a public key file'
      newState.error = true
      this.setState({ ...newState })
    } else {
      let newState = this.state
      newState.errorMessage = ''
      newState.error = false
      this.setState({ ...newState })
    }

    return validated
  }

  showModal() {
    const { classes } = this.props
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.showModal === true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ showModal: false })}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          {this.state.connectionErrorMessage}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.setState({ showModal: false })} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  getBackButton() {
    if (this.state.supplierMapping.backButtonOnClick)
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <IconButton onClick={this.state.supplierMapping.backButtonOnClick}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
      )
    return ''
  }
  render() {
    const { classes } = this.props
    if (!this.state.supplierMapping)
      return (
        <GridContainer justify="center">
          {this.getBackButton()}
          <GridItem xs={12} sm={12} md={12}>
            no Mapping Prop
          </GridItem>
        </GridContainer>
      )
    else if (!this.state.supplierMapping.service) {
      return (
        <GridItem xs={12} sm={12} md={12}>
          {this.getBackButton()}
          no service
        </GridItem>
      )
    } else
      return (
        <GridContainer justify="center">
          {this.getBackButton()}
          {this.state.supplierMapping.documentationPath ? (
            <GridItem xs={12} sm={12} md={12}>
              <Button
                simple
                className={classes.documentation}
                href={this.state.supplierMapping.documentationPath}
                target="_blank"
              >
                <Info className={classes.icons} />
                Download Documentation
              </Button>
            </GridItem>
          ) : (
            ''
          )}
          {this.state.supplierMapping.title ? (
            <GridItem xs={12} sm={12}>
              <h4 className={classes.infoText}>{this.state.supplierMapping.title}</h4>
            </GridItem>
          ) : (
            ''
          )}
          <GridItem xs={12} sm={12}>
            {this.state.supplierMapping.hideServiceName ? (
              ''
            ) : (
              <h5 className={classes.sectionTitle}>
                {this.state.supplierMapping.service.toUpperCase()}
              </h5>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <div className={classes.displayTable} style={{ paddingTop: '15px' }}>
                    <div className={classes.verticalAlign}>{'PII Public Key Type'}</div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <div
                    style={{
                      width: '100%',
                      padding: '13px 0px 13px'
                    }}
                  >
                    <FormControl style={{ width: '100%' }} className={classes.formControl}>
                      <GridContainer>
                        <GridItem xs={12} style={{ height: '20px' }}>
                          <InputLabel className={classes.inputLabel}>
                            {
                              <div>
                                <span>{'PII Public Key Type'}</span>
                              </div>
                            }
                          </InputLabel>
                        </GridItem>
                        <GridItem xs={10}>
                          <Select
                            styles={{
                              valueContainer: (provided, state) => {
                                return {
                                  ...provided,
                                  maxHeight: '150px',
                                  overflowY: 'auto'
                                }
                              }
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              this.state.supplierMapping.fields.piiEncriptionKeyType ===
                              'certificate'
                                ? { label: 'Certificate', value: 'certificate' }
                                : { label: 'Pem Key', value: 'pemKey' }
                            }
                            onChange={e => {
                              let newState = this.state
                              newState.supplierMapping.fields.piiEncriptionKeyType = e.value
                              this.setState({ ...newState })
                            }}
                            options={[
                              { label: 'Certificate', value: 'certificate' },
                              { label: 'Pem Key', value: 'pemKey' }
                            ]}
                          ></Select>
                        </GridItem>
                      </GridContainer>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <div className={classes.displayTable} style={{ paddingTop: '15px' }}>
                    {this.state.error ? (
                      <div id={'piiFileNameshowErrorIconDiv'} style={{ paddingBottom: '15px' }}>
                        {this.state.errorMessage ? (
                          <Tooltip title={this.state.errorMessage}>
                            <ErrorOutlineIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title={''}>
                            <ErrorOutlineIcon />
                          </Tooltip>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <Hidden only={['xs']}>
                      <div className={classes.verticalAlign}>
                        <FormLabel id={'piiFileName'} className={classes.inputLabel}>
                          {'PII Certificate/Key File'}
                        </FormLabel>
                      </div>
                    </Hidden>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <GridContainer justify="center">
                    <CustomInput
                      labelText={''}
                      id={'piiFileName'}
                      inputInternalProps={{
                        style: {
                          width: '100%',
                          textAlign: 'center'
                        }
                      }}
                      inputProps={{
                        disabled: true,
                        type: 'text',
                        value: this.state.supplierMapping.fields.piiEncryptionFileName,
                        onChange: event => {
                          let stateCopy = this.state
                          stateCopy.supplierMapping.fields.piiEncryptionFileName =
                            event.target.value
                          this.setState(stateCopy)
                        }
                      }}
                    />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <div className={classes.displayTable} style={{ paddingTop: '15px' }}>
                    <div className={classes.verticalAlign}>
                      <FormLabel className={classes.inputLabel}>
                        {'Upload/Update certificate/key file'}
                      </FormLabel>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <GridContainer>
                    <DragAndDrop
                      handleFile={this.handleFile}
                      title={'Encryption Certificate/Key'}
                    />
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
          <div style={{ paddingTop: '20px', paddingLeft: '18px', paddingRight: '15px' }}>
            <Alert severity="info" variant="filled">
              Our API returns the filename of the certificate used to encrypt your PII data.
              Therefore, we recommend using certificates with different filenames so that you can
              distinguish them while performing the decryption.
            </Alert>
          </div>
        </GridContainer>
      )
  }
}

piiEmails.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(piiEmails)
