import XLSX from 'xlsx'

function getSuppliersFromFile(file, callback = () => {}) {
  const reader = new FileReader()
  reader.onload = e => {
    parseXlsxToJson(e.target.result, callback)
  }
  reader.readAsBinaryString(file)
}

function parseXlsxToJson(binaryContent, callback) {
  /* Parse data */
  const workBook = XLSX.read(binaryContent, { type: 'binary' })
  const workSheetName = workBook.SheetNames[0]
  const workSheet = workBook.Sheets[workSheetName]
  const suppliers = XLSX.utils
    .sheet_to_json(workSheet, { header: 1 })
    .filter(row => row.length > 0 && row.some(cell => cell))
  const supplierCount = suppliers.length - 1 //remove header row
  callback(binaryContent, supplierCount)
}

export { getSuppliersFromFile, parseXlsxToJson }
