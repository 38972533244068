import { googleConstantsAPI } from 'utils/Constants'
import { v4 as uuidv4 } from 'uuid'

import { getUploadS3Url, transformOpWrite, uploadFileToS3 } from 'services/apiDataAssure.js'

/*eslint-disable*/
import { Button, Fade, Grid, IconButton, SvgIcon } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getSuppliersFromBinary, getSuppliersFromFile, parseXlsxToJson } from './utils/utils'

// @material-ui/core components
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

// @material-ui/icons
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import TouchApp from '@material-ui/icons/TouchApp'
import { ReactComponent as GoogleDriveIcon } from '../../assets/img/data-assure/GoogleDriveIcon.svg'

// components
import Card from '../../components/Card/Card.js'
import CardBody from '../../components/Card/CardBody.js'
import CardHeader from '../../components/Card/CardHeader.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { uploadDataAssureBatch } from '../../services/apiApps'

import DragAndDrop from './DaDragDropFile'

//Styles
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

import { Spinner } from '../../components/Loader/Spinner'

//import useDrivePicker from 'react-google-drive-picker'

import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import logo from '../../assets/img/logo.svg'
import useScript from '../../hooks/POC/useScript'

// Utils
import { hideLoading, showLoading } from 'utils/functions'

import InfoModal from 'components/Invoice/InfoModal'
import moment from 'moment'

export default function DaBatchNew({ setSelectedView, selectedAppId, setBatches }) {
  const SCOPES = [
    'https://www.googleapis.com/auth/drive',
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/drive.readonly'
  ]

  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest']

  const [gapiLoaded, errorGapi] = useScript('https://apis.google.com/js/api.js')
  const [gisLoaded, errorGis] = useScript('https://accounts.google.com/gsi/client')
  const [gapiInited, setGapiInited] = useState(false)
  const [gisInited, setGisInited] = useState(false)
  const [tokenClient, setTokenClient] = useState(null)
  const [token, setToken] = useState(null)
  const [authRes, setAuthRes] = useState()

  const [supplierCount, setSupplierCount] = useState(0)
  const [fileLoaded, setFileLoaded] = useState(false)
  const [fileLoadedName, setFileLoadedName] = useState(null)
  const [binaryContent, setBinaryContent] = useState(null)
  const [alert, setAlert] = useState(null)

  const classes = useStyles()

  // get the apis from googleapis
  useEffect(() => {
    if (gapiLoaded && !errorGapi && gisLoaded && !errorGis) {
      window.gapi.load('client:picker', {
        callback: async () => initializeGapi()
      })
      initializeGis()
    }
  }, [gapiLoaded, errorGapi, gisLoaded, errorGis])

  // get the apis from googleapis
  useEffect(() => {
    if (authRes) {
      createPicker()
    }
  }, [authRes])

  useEffect(() => {
    if (tokenClient) {
      setGisInited(true)
    }
  }, [tokenClient])

  /**
   * Callback after the API client is loaded. Loads the discovery doc to initialize the API.
   */
  async function initializeGapi() {
    await window.gapi.client.init({
      apiKey: googleConstantsAPI.apiKey,
      discoveryDocs: DISCOVERY_DOCS
    })
    setGapiInited(true)
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  function initializeGis() {
    setTokenClient(
      google.accounts.oauth2.initTokenClient({
        client_id: googleConstantsAPI.clientId,
        scope: SCOPES.join(' '),
        callback: tokenResponse => {
          if (tokenResponse.error !== undefined) {
            throw response
          }
          setAuthRes(tokenResponse)
        }
      })
    )
  }

  // open the picker
  const openPicker = () => {
    // if we didnt get token generate token.

    tokenClient.requestAccessToken()
  }

  const createPicker = () => {
    console.table(googleConstantsAPI)
    const view = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
    //view.setEnableDrives(true)
    view.setIncludeFolders(true)
    view.setSelectFolderEnabled(false)
    view.setMode(google.picker.DocsViewMode.LIST)

    const picker = new google.picker.PickerBuilder()
      .addView(view)
      //.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setAppId(googleConstantsAPI.appId)
      .setDeveloperKey(googleConstantsAPI.apiKey)
      .setOAuthToken(authRes.access_token)
      .setLocale('en-US')
      .setTitle('Select file to be processed by Data Assure')
      .setCallback(async data => {
        if (data.action === google.picker.Action.PICKED) {
          console.log(JSON.stringify(data))
          setFileLoaded(true)
          setFileLoadedName(data.docs[0].name)
          exportDriveFileToXls(data.docs[0].id)
        } else {
          console.log(`event from drive picker: ${data.action}`)
        }
      })
      .build()
    picker.setVisible(true)
  }

  const exportDriveFileToXls = async fileId => {
    showLoading()

    let response
    response = await gapi.client.drive.files.export({
      fileId: fileId,
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const binaryContent = response.body

    await parseXlsxToJson(binaryContent, (binaryContent, supplierCount) => {
      validateBatchLimit(binaryContent, supplierCount)
      hideLoading()
    })
  }

  const validateBatchLimit = (binaryContent, supplierCount) => {
    if (supplierCount > 10000) {
      setAlert(
        <ModalWrapper>
          <InfoModal
            title={'Batch limit'}
            warningMessage={`Batches are limited to 10,000 transactions. Suppliers in file: ${supplierCount}`}
            fullWidth={true}
            maxWidth="sm"
            showModal={true}
            successBtn={false}
            transitionComponent={Fade}
            onClose={() => hideAlert()}
          />
        </ModalWrapper>
      )
      setFileLoaded(false)
      setFileLoadedName(null)
    } else {
      setSupplierCount(supplierCount)
      setBinaryContent(binaryContent)
    }
  }

  const uploadSpreadsheetBatch = async () => {
    showLoading()
    const uuidSpllitted = uuidv4().split('-')

    const spreadsheetBatchId = `${moment().format('YYYYMMDDHHmmss')}-${
      uuidSpllitted[uuidSpllitted.length - 1]
    }`
    const folderName = spreadsheetBatchId
    const fileName = `${spreadsheetBatchId}.xlsx`
    const presignedUrlResponse = await getUploadS3Url(
      selectedAppId,
      'spreadsheet',
      folderName,
      fileName
    )
    const uploadFileToS3Result = await uploadFileToS3(
      presignedUrlResponse.data.url,
      new Buffer(binaryContent, 'binary')
    )
    await transformOpWrite(selectedAppId, {
      source: 'spreadsheet',
      externalId: spreadsheetBatchId,
      inputS3Files: [
        {
          fileName: fileLoadedName,
          s3ObjectKey: presignedUrlResponse.data.s3ObjectKey,
          fileCategory: 'spreadsheet'
        }
      ]
    })
    setSelectedView('main')
    hideLoading()
  }

  const hideAlert = () => {
    setAlert(null)
  }

  return (
    <>
      {alert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} className={classes.padding10}>
          <Card style={{ minHeight: '300px' }}>
            <CardHeader>
              <h3 className={classes.formHeader}>Upload Data Assure Batch</h3>
              <Tooltip title="Return to main view">
                <Button
                  color="primary"
                  className={classes.addButton}
                  onClick={e => setSelectedView('main')}
                >
                  <ArrowBackIcon className={classes.icons} />
                </Button>
              </Tooltip>
            </CardHeader>
            <CardBody style={{ textAlign: 'center' }}>
              <DragAndDrop
                title="Data Assure File"
                handleFile={file => {
                  showLoading()
                  setFileLoaded(true)
                  setFileLoadedName(file.name)
                  getSuppliersFromFile(file, (binaryContent, supplierCount) => {
                    validateBatchLimit(binaryContent, supplierCount)
                    hideLoading()
                  })
                }}
              />
              <br />
              <div>
                {(gapiInited && gisInited && (
                  <IconButton onClick={() => openPicker()}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <p>{'Select from Google Drive'}</p>
                      </div>
                      <div>
                        <SvgIcon component={GoogleDriveIcon} viewBox="0 0 87.3 78" />
                      </div>
                    </div>
                  </IconButton>
                )) || <SyncLoader textLoader="Loading google drive..." />}
              </div>
              <br />
              <div>
                {(fileLoaded &&
                  ((supplierCount && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={async () => {
                        uploadSpreadsheetBatch(supplierCount)
                      }}
                    >{`Upload ${supplierCount} Supplier(s)`}</Button>
                  )) || <h3>No Suppliers found</h3>)) || <></>}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  )
}
