import { useQuery } from 'react-query'
import { getConnectivityConfiguration } from 'services/apiInvoices'

export function useGetConnectivityConfiguration(appId) {
  return useQuery(
    ['Apps', appId, 'connectivity'],
    () => getConnectivityConfiguration(appId).then(result => result.data),
    {
      enabled: Boolean(appId),
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}
