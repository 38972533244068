import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { formatDate } from 'utils/functions.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle'
const useStyles = makeStyles(styles)

export function PIIDetailsModal (props) {
  const classes = useStyles()
  const { showModal, modalInfo, onClose } = props

  return (
    <Dialog
      classes={{
        root: classes.center + ' ' + classes.modalRoot
      }}
      open={showModal}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={() => onClose()}
    >
      <DialogTitle>PII Supplier Details</DialogTitle>
      <DialogContent className={classes.modalBody}>
        {typeof modalInfo === 'object' ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalInfo && modalInfo.length > 0 ? (
                modalInfo.map((info, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {info.date
                        ? formatDate({
                            date: new Date(info.date).toISOString()
                          })
                        : 'Invalid Date'}
                    </TableCell>
                    <TableCell>
                      {info.message ? info.message.replace(/\/n/g, ' ') : ''}
                    </TableCell>
                    <TableCell>
                      {info.status.charAt(0).toUpperCase() +
                        info.status.replace('_', ' ').substring(1)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>No information</TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          'No information to show'
        )}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => onClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
