import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js'
import { blackColor, grayColor, hexToRgb, defaultFont } from 'assets/jss/material-dashboard-pro-react.js'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.js'

const piiStyle = theme => ({
  appBar: {
    overflow: 'auto',
    '@media (min-width: 1300px)': {
      height: '100%'
    }
  },
  listItem: {
    color: primaryColor[0]
  },
  circularProgress: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearAvatar: {
    backgroundColor: 'transparent'
  },
  formHeader: {
    textAlign: 'center'
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  },
  activeToggle: {
    marginLeft: '0px'
  },
  documentation: {
    float: 'right',
    textTransform: 'none'
  },
  socialButtonsIcons: {
    fontSize: '18px',
    marginTop: '-2px',
    position: 'relative'
  },
  sectionTitle: {
    fontWeight: 'bold'
  },
  contentLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px',
    paddingTop: '50px'
  },
  inputLabelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    display: 'inline-flex',
    fontSize: '14px',
    '@media (min-width: 992px)': {
      paddingTop: '30px'
    }
  },
  toggleTitle: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  row: {
    paddingTop: '20px'
  },
  inputDisabled: {
    background: 'gray'
  },
  customDisabled: {
    '&$disabled': {
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)'
    }
  },
  disabled: {},
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: grayColor[2],
    fontSize: '18px'
  },
  cardHeader: {
    zIndex: '3'
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: '14px',
    margin: '0 0 10px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  marginRight: {
    marginRight: '5px'
  },
  modalSectionTitle: {
    marginTop: '30px'
  },
  appRow: {
    paddingTop: '20px',
    borderBottom: grayColor[0] + '!important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[4] + '!important'
      },
      '&:after': {
        backgroundColor: grayColor[0] + '!important',
        borderBottomColor: grayColor[0] + '!important'
      }
    }
  },
  addButton: {
    marginTop: '-25px',
    float: 'right'
  },
  disabledApp: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)'
  },
  floatRight: {
    float: 'right'
  },
  bgColorPrimary: {
    backgroundColor: '#081c3e',
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: 'rgba(8,28,62, 0.7)',
      color: 'white'
    }
  },
  colorPrimary: {
    color: '#081c3e'
  },
  reloadSize: {
    width: '100%',
    height: '240px',
    textAlign: 'center',
    padding: '50px'
  },
  defaultColor: {
    color: '#999',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#999'
    }
  },
  defaultColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#999'
  },
  integratedColor: {
    color: '#90EE90',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#90EE90'
    }
  },
  integratedColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#90EE90'
  },
  tokenExpiredColor: {
    color: '#A52A2A',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#A52A2A'
    }
  },
  tokenExpiredColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#A52A2A'
  },
  inProgressColor: {
    color: '#87CEFA',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#87CEFA'
    }
  },
  inProgressColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#87CEFA'
  },
  notInvitedColor: {
    color: '#D2691E',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#D2691E'
    }
  },
  notInvitedColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#D2691E'
  },
  primaryColor: {
    color: '#081c3e',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#081c3e'
    }
  },
  primaryColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#081c3e'
  },
  warningColor: {
    color: '#ff9800',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#ff9800'
    }
  },
  warningColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#ff9800'
  },
  exceptionColor: {
    color: '#d2d818',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#d2d818'
    }
  },
  exceptionColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#d2d818'
  },
  dangerColor: {
    color: '#f44336',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#f44336'
    }
  },
  dangerColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#f44336'
  },
  successColor: {
    color: '#4caf50',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#4caf50'
    }
  },
  successColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#4caf50'
  },
  statusButton: {
    display: 'inline-block',
    height: '40px',
    borderRight: '1px solid #80808040',
    textAlign: 'center',
    marginBottom: '10px'
  },
  completedColor: {
    color: '#228B22',
    borderRadius: '30px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
      color: '#228B22'
    }
  },
  completedColorFilled: {
    color: 'white',
    borderRadius: '30px',
    backgroundColor: '#228B22'
  },
  statusText: { marginLeft: '7px', color: 'grey', fontSize: '15px' },
  ...modalStyle(theme),
  ...buttonStyle
})

export default piiStyle
