import React from 'react'
import { Grid } from '@material-ui/core'
const SheetJSFT = ['xlsx', 'xls']

class DataInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    const files = e.target.files
    if (files && files[0]) this.props.handleFile(files[0])
  }
  render() {
    return (
      <Grid container>
        <form className="form-inline">
          <Grid item xs={12}>
            <input
              type="file"
              className="form-control"
              id={this.props.id}
              accept={SheetJSFT}
              onChange={this.handleChange}
              style={{ display: 'none' }}
            />
          </Grid>
        </form>
      </Grid>
    )
  }
}

class DragDropFile extends React.Component {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
  }
  suppress(evt) {
    evt.stopPropagation()
    evt.preventDefault()
  }
  onDrop(evt) {
    evt.stopPropagation()
    evt.preventDefault()
    const files = evt.dataTransfer.files
    if (files && files[0]) this.props.handleFile(files[0])
  }
  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100px',
          backgroundColor: '#001489',
          position: 'relative',
          color: 'white'
        }}
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
        onClick={() => {
          let element = document.getElementById('aribaExcelFile')
          if (element && element.click) element.click()
        }}
      >
        <div
          style={{
            margin: '0',
            position: 'absolute',
            top: '50%',
            left: '50%',

            transform: 'translate(-50%, -50%)'
          }}
        >
          <h4 style={{ fontSize: '0.9vmax' }}>CLICK OR DRAG A FILE TO THIS AREA TO UPLOAD</h4>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default class DragAndDrop extends React.Component {
  constructor(props) {
    super(props)
    this.state = { selectedFileName: '' }
  }
  render() {
    return (
      <>
        {this.state.selectedFileName ? <h5>{this.state.selectedFileName}</h5> : ''}
        <DragDropFile
          handleFile={file => {
            let newState = { ...this.state }
            newState.selectedFileName = file.name
            this.setState(newState)

            this.props.handleFile(file)
          }}
        >
          <div className="row">
            <div className="col-xs-12">
              <DataInput
                handleFile={file => {
                  let newState = { ...this.state }
                  newState.selectedFileName = file.name
                  this.setState(newState)
                  this.props.handleFile(file)
                }}
                id={'aribaExcelFile'}
              />
            </div>
          </div>
        </DragDropFile>
      </>
    )
  }
}
